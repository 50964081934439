import { log } from "~/composables/log";

export default defineNuxtRouteMiddleware(() => {
    if (__subscription().isReadOnlyS3) {
        log("❌ Read-only S3");
        return navigateTo("/");
    }

    log("✅ Editable S3");
});
